import React from "react";
import { Row, Col } from "react-bootstrap";
import { BaseSelect, BaseForm, BaseFieldSet } from "react-epharma-components";

export default class CrudForm extends React.Component {
    private branchCompanyId = React.createRef<any>();

    private refsArray = [
        this.branchCompanyId
    ];

    render() {
        return (
            <BaseForm refs={this.refsArray} {...this.props}>
                <BaseFieldSet legend="Filial">
                    <Row>
                        <Col>
                            <BaseSelect
                                id="ok"
                                label="Empresa"
                                name="branchCompanyId"
                                type="number"
                                required
                                endpoint={`${window.serviceUrl}/company/api/v2/CompanyBranch/ReadNotAssociatedCompanyBranches`}
                                valuePropertyName="id"
                                labelPropertyName="corporateName"
                                ref={this.branchCompanyId}
                            />
                        </Col>
                    </Row>
                </BaseFieldSet>
            </BaseForm>
        );
    }
}
import React from 'react'
import { BaseGrid, BaseToolbar, ITableColumn,ICrud } from 'react-epharma-components';
import CrudForm from './Crud';

class CompanyBankScreen extends React.Component<{[k: string]: any}> {
    
    private columns: ITableColumn[] = [
        { identifier: true, name: "id", displayName: "Id", sortable: true, width: "5%" },
        { name: "bank.code", displayName: "Código", sortable: true, width: "15%" },
        { name: "bank.name", displayName: "Banco", sortable: true, width: "45%" },
        { name: "agencyNumber", displayName: "Agência", sortable: true, width: "15%" },
        { name: "accountNumber", displayName: "Conta", sortable: true, width: "20%" }
    ];
    
    private crud: ICrud = {
        form: <CrudForm />,
        create: {
            show: true,
            permissionId: "#CADUNICOBANKCREATE"
        },
        read: {
            show: true
        },
        // log: {
        //     show: true,
        //     id: 26, 
        //     keys: "id|cnpj"
        // },
        update: {
            show: true,
            permissionId: "#CADUNICOBANKUPDATE"
        }
    };

    render() {
        return (
            <BaseGrid
                name="company-bank"
                baseEndpoint={`${window.serviceUrl}/company/api/v2/companyBank`}
                toolbar={
                    <BaseToolbar crud={this.crud}/>
                }
                mode={{
                        type: "automatic",
                        automatic: {
                            endpoint: "/Read"
                        },
                        autoload: false,
                        load: this.props.load
                }}
                columns={this.columns}
                filter={this.props.filter}
                dependentProperty={this.props.dependentProperty}
            />
        );
    }
}

export default CompanyBankScreen
import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  BaseInput,
  BaseSelect,
  BaseForm,
  BaseFieldSet,
  PhoneInput,
  CpfInput
} from "react-epharma-components";

export default class CrudForm extends React.Component {
  private id = React.createRef<any>();
  private personId = React.createRef<any>();
  private name = React.createRef<any>();
  private companyPersonTypeId = React.createRef<any>();
  private maritalStatusId = React.createRef<any>();
  private profession = React.createRef<any>();
  private cpf = React.createRef<any>();
  private rg = React.createRef<any>();
  private phoneNumber = React.createRef<any>();
  private nationality = React.createRef<any>();
  private email = React.createRef<any>();
 
  private refsArray = [
    this.personId,
    this.id,
    this.name,
    this.companyPersonTypeId,
    this.maritalStatusId,
    this.profession,
    this.cpf,
    this.rg,
    this.phoneNumber,
    this.nationality,
    this.email
  ];

  render() {
    return (
      <BaseForm refs={this.refsArray} {...this.props}>
        <BaseFieldSet legend="Rep. Sócios e Testemunhas">
          <BaseInput
                name="personId"
                label="Person Id"
                type="text"
                readOnly
                hidden
                ref={this.personId}
                defaultValue={0}
              />
          <Row>
            <Col xs="12" sm="4" md="3" lg="2" xl="2">
              <BaseInput
                name="id"
                label="Id"
                type="text"
                readOnly
                ref={this.id}
                identifier
              />
            </Col>
            <Col>
              <BaseSelect
                id="ok"
                label="Tipo"
                name="companyPersonTypeId"
                type="number"
                endpoint={`${window.serviceUrl}/company/api/v2/CompanyPersonType/Read`}
                ref={this.companyPersonTypeId}
                required
              />
            </Col>
            <Col>
              <BaseInput
                label="Nome"
                name="name"
                mapping="person.name"
                type="text"
                ref={this.name}
                required
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <BaseInput
                label="Nacionalidade"
                name="nationality"
                mapping="person.nationality"
                type="text"
                ref={this.nationality}
              />
            </Col>
            <Col>
              <BaseSelect
                id="ok"
                label="Estado Civil"
                name="maritalStatusId"
                mapping="person.maritalStatusId"
                type="number"
                endpoint={`${window.serviceUrl}/common/api/v1/MaritalStatus/Read`}
                ref={this.maritalStatusId}
              />
            </Col>
            <Col>
              <BaseInput
                label="Profissão"
                name="profession"
                mapping="person.profession"
                type="text"
                ref={this.profession}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CpfInput name="cpf" mapping="person.cpf" innerRef={this.cpf} required />
            </Col>
            <Col>
              <BaseInput
                label="RG"
                name="rg"
                mapping="person.rg"
                type="text"
                ref={this.rg}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PhoneInput
                label="Telefone"
                name="personBusinessPhone"
                phoneType="cell_phone"
                innerRef={this.phoneNumber}
                required
              />
            </Col>
            <Col>
              <BaseInput
                label="E-mail"
                name="email"
                mapping="person.email"
                type="email"
                ref={this.email}
                required
              />
            </Col>
          </Row>
        </BaseFieldSet>
      </BaseForm>
    );
  }
}

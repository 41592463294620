import React from "react";
import {
  BaseGrid,
  BaseToolbar,
  ICrud,
  ITableColumn,
  CPF_MASK
} from "react-epharma-components";
import CrudForm from "./Crud";

class CompanyTradeRepPartnerWitnessScreen extends React.Component<{
  [k: string]: any;
}> {
  private columns: ITableColumn[] = [
    {
      identifier: true,
      name: "id",
      displayName: "Id",
      sortable: true,
      width: "5%"
    },
    { name: "person.name", displayName: "Nome", sortable: true, width: "40%" },
    {
      name: "companyPersonType.description",
      displayName: "Tipo",
      sortable: true,
      width: "15%"
    },
    {
      name: "person.cpf",
      displayName: "CPF",
      sortable: true,
      width: "15%",
      mask: CPF_MASK
    },
    {
      name: "person.email",
      displayName: "E-mail",
      sortable: true,
      width: "25%"
    }
  ];

  private crud: ICrud = {
    form: <CrudForm />,
    create: {
      show: true,
      permissionId: "#CADUNICOPRSNCREATE"
    },
    read: {
      show: true
    },
    // log: {
    //     show: true,
    //     id: 26,
    //     keys: "id|cnpj"
    // },
    update: {
      show: true,
      permissionId: "#CADUNICOPRSNUPDATE"
    }
  };

  render() {
    return (
      <BaseGrid
        name="company-partner"
        baseEndpoint={`${window.serviceUrl}/company/api/v2/companyPerson`}
        toolbar={<BaseToolbar crud={this.crud} />}
        mode={{
          type: "automatic",
          automatic: {
            endpoint: "/Read"
          },
          autoload: false,
          load: this.props.load
        }}
        columns={this.columns}
        filter={this.props.filter}
        dependentProperty={this.props.dependentProperty}
      />
    );
  }
}

export default CompanyTradeRepPartnerWitnessScreen;

import React from "react";
//import { BaseScreen, BaseNavTabs } from "../base";
import { BaseScreen, BaseNavTabs } from "react-epharma-components";
import CompanyScreen from "./company/Company";
import CompanyAddressScreen from "./address/Address";
import CompanyContactScreen from "./contact/Contact";
import CompanyTradeRepPartnerWitnessScreen from "./trade-rep-partner-witness/TradeRepPartnerWitness";
import CompanyBankScreen from "./bank/Bank";
import CompanySubsidiaryScreen from "./subsidiary/Subsidiary";

export default class CadastroUnico extends React.Component {
    state = {
        data: []
    }
    
    onSelect = (data: any[]) => {
        this.setState({ 
            data
        });
    }

    render() {
        return (
            <BaseScreen 
                title="Clientes" 
                description="Manutenção de CNPJ" 
            >                
                <BaseNavTabs
                    navs={[
                        {
                            key: 'company',
                            name: "Empresas",
                            content: <CompanyScreen 
                                onSelect={this.onSelect}
                            />
                        },
                        {
                            key: 'company-subsidiary',
                            name: "Filiais",
                            content: <CompanySubsidiaryScreen />,
                            disabled: this.state.data.length === 0 || 
                                this.state.data.some((value: any) => !value.isParent)
                        },
                        {
                            key: 'company-address',
                            name: "Endereços",
                            content: <CompanyAddressScreen />
                        },
                        {
                            key: 'company-contact',
                            name: "Contatos",
                            content: <CompanyContactScreen />
                        },
                        {
                            key: 'company-trade-rep-partner-witness',
                            name: "Rep. Sócios e Testemunhas",
                            content: <CompanyTradeRepPartnerWitnessScreen />
                        },
                        {
                            key: 'company-bank',
                            name: "Bancos",
                            content: <CompanyBankScreen />
                        }
                    ]}
                    defaultActiveKey="company"
                    data={this.state.data}
                    parentPropertyName="id"
                    childPropertyName="companyId"
                />
            </BaseScreen>
        )
    }
}
import React from "react";
import {
  BaseGrid,
  BaseToolbar,
  CELL_PHONE_MASK,
  ICrud,
  ITableColumn
} from "react-epharma-components";
import CrudForm from "./Crud";

class CompanyContactScreen extends React.Component<{ [k: string]: any }> {
  private columns: ITableColumn[] = [
    { name: "id", displayName: "Id", sortable: true, width: "5%" },
    { name: "name", displayName: "Nome", sortable: true, width: "40%" },
    {
      name: "contactType.description",
      displayName: "Tipo",
      sortable: true,
      width: "15%"
    },
    {
      name: "cellPhoneNumber",
      displayName: "Celular",
      sortable: true,
      width: "15%",
      mask: CELL_PHONE_MASK
    },
    { name: "email", displayName: "E-mail", sortable: true, width: "25%" }
  ];

  private crud: ICrud = {
    form: <CrudForm />,
    create: {
      show: true,
      permissionId: "#CADUNICOCNTCTCREATE"
    },
    read: {
      show: true
    },
    // log: {
    //     show: true,
    //     id: 26,
    //     keys: "id|cnpj"
    // },
    update: {
      show: true,
      permissionId: "#CADUNICOCNTCTUPDATE"
    }
  };

  render() {
    return (
      <BaseGrid
        name="company-contact"
        baseEndpoint={`${window.serviceUrl}/company/api/v2/contact`}
        toolbar={<BaseToolbar crud={this.crud} />}
        mode={{
          type: "automatic",
          automatic: {
            endpoint: "/Read"
          },
          autoload: false,
          load: this.props.load
        }}
        columns={this.columns}
        filter={this.props.filter}
        dependentProperty={this.props.dependentProperty}
      />
    );
  }
}

export default CompanyContactScreen;

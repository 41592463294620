import React from "react";
import {
  ICrud,
  ITableColumn,
  BaseGrid,
  BaseToolbar,
  CNPJ_MASK
} from "react-epharma-components";
 
import CrudForm from "./Crud";
import SearchForm from "./Search";
  
class CompanyScreen extends React.Component<{ onSelect(data: any[]): void }> {
  private columns: ITableColumn[] = [
    { name: "id", displayName: "Id", sortable: true, width: "5%" },
    {
      name: "corporateName",
      displayName: "Razão Social",
      sortable: true,
      width: "35%"
    },
    {
      name: "cnpj",
      displayName: "Cnpj",
      sortable: true,
      width: "20%",
      mask: CNPJ_MASK
    },
    {
      name: "isParent",
      displayName: "Tipo",
      sortable: true,
      width: "10%",
      onRender: value => (value ? "Matriz" : "Filial")
    },
    {
      name: "status.description",
      displayName: "Status",
      sortable: true,
      width: "15%"
    },
    {
      name: "createdOn",
      displayName: "Data de Cadastro",
      sortable: true,
      width: "15%",
      type: "datetime"
    }
  ];

  private crud: ICrud = {
    form: <CrudForm />,
    create: {
      show: true,
      permissionId: "#CADUNICOEMPCREATE"
    },
    read: {
      show: true
    },
    // log: {
    //     show: true,
    //     id: 26,
    //     keys: "id|cnpj"
    // },
    update: {
      show: true,
      permissionId: "#CADUNICOEMPUPDATE"
    },
    exportGrid: {
      show: true,
      permissionId: "#CADUNICOEMPEXPORT"
    }
  };

  render() {
    return (
      <BaseGrid
        name="company"
        baseEndpoint={`${window.serviceUrl}/company/api/v2/company`}
        toolbar={
          <BaseToolbar
            crud={this.crud}
            search={{
              show: true,
              form: <SearchForm />
            }}
          />
        }
        mode={{
          type: "automatic",
          automatic: {
            endpoint: "/Read"
          }
        }}
        columns={this.columns}
        onSelect={this.props.onSelect}
      />
    );
  }
}

export default CompanyScreen;

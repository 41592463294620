import React from "react";
import { Row, Col } from "react-bootstrap";
//import { BaseInput, BaseForm, CnpjInput, BaseFieldSet, BaseSelect } from "../../base";
import {
  BaseInput,
  BaseForm,
  CnpjInput,
  BaseFieldSet,
  BaseSelect,
  FilterOperator
} from "react-epharma-components";

export default class SearchForm extends React.Component {
  private corporateName = React.createRef<any>();
  private cnpj = React.createRef<any>();
  private status = React.createRef<any>();

  render() {
    return (
      <BaseForm
        refs={[this.corporateName, this.cnpj, this.status]}
        {...this.props}
      >
        <BaseFieldSet legend="Filtros">
          <Row>
            <Col xs="12" sm="12" md="6" lg="6" xl="6">
              <BaseInput
                name="corporateName"
                label="Razão Social"
                type="text"
                maxLength={60}
                filterOperator={FilterOperator.Contains}
                ref={this.corporateName}
              />
            </Col>
            <Col xs="12" sm="12" md="3" lg="3" xl="3">
              <CnpjInput name="cnpj" type="text" innerRef={this.cnpj} />
            </Col>
            <Col xs="12" sm="12" md="3" lg="3" xl="3">
              <BaseSelect
                name="statusId"
                label="Status"
                type="number"
                endpoint={`${window.serviceUrl}/common/api/v1/Status/Read`}
                ref={this.status}
              />
            </Col>
          </Row>
        </BaseFieldSet>
      </BaseForm>
    );
  }
}

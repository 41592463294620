import React from 'react'
import { BaseGrid, BaseToolbar, ITableColumn, ICrud , CEP_MASK} from 'react-epharma-components';
import CrudForm from './Crud';
  
class CompanyAddressScreen extends React.Component<{[k: string]: any}> {    
    private columns: ITableColumn[]  = [
        { name: "id", displayName: "Id", sortable: true, width: "5%" },
        { name: "address", displayName: "Endereço", sortable: true, width: "30%" },
        { name: "addressNumber", displayName: "Número", sortable: true, width: "10%" },
        { name: "neighborhood", displayName: "Bairro", sortable: true, width: "15%" },
        { name: "city.name", displayName: "Cidade", sortable: true, width: "15%" },
        { name: "city.state.name", displayName: "Estado", sortable: true, width: "15%" },
        { name: "zipcode", displayName: "CEP", sortable: true, width: "10%", mask: CEP_MASK },
    ];
    
    private crud: ICrud = {
        form: <CrudForm />,
        create: {
            show: true,
            permissionId: "#CADUNICOADDRSCREATE"
        },
        read: {
            show: true
        },
        // log: {
        //     show: true,
        //     id: 26, 
        //     keys: "id|cnpj"
        // },
        update: {
            show: true,
            permissionId: "#CADUNICOADDRSUPDATE"
        }
    };

    render() {
        return (
            <BaseGrid
                name="company-address" 
                baseEndpoint={`${window.serviceUrl}/company/api/v2/CompanyAddress`}
                toolbar={
                    <BaseToolbar 
                        crud={this.crud}
                    />
                }
                mode={{
                        type: "automatic",
                        automatic: {
                            endpoint: "/Read"
                        },
                        autoload: false,
                        load: this.props.load
                }}
                columns={this.columns}
                filter={this.props.filter}
                dependentProperty={this.props.dependentProperty}
            />
        )
    }
}

export default CompanyAddressScreen
import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  BaseInput,
  BaseSelect,
  BaseForm,
  CnpjInput,
  BaseFieldSet,
  BaseCheckboxRadioSwitch
} from "react-epharma-components";

export default class CrudForm extends React.Component {
  private id = React.createRef<BaseInput>();
  private corporateName = React.createRef<BaseInput>();
  private cnpj = React.createRef<BaseInput>();
  private createdOn = React.createRef<BaseInput>();
  private tradeName = React.createRef<BaseInput>();
  private stateRegistration = React.createRef<BaseInput>();
  private municipalRegistration = React.createRef<BaseInput>();
  private website = React.createRef<BaseInput>();
  private comments = React.createRef<BaseInput>();
  private status = React.createRef<BaseSelect>();
  private parent = React.createRef<BaseCheckboxRadioSwitch>();
  private companyTaxType = React.createRef<BaseSelect>();
  private fieldActivity = React.createRef<BaseInput>();

  private inputRefs = [
    this.id,
    this.corporateName,
    this.cnpj,
    this.createdOn,
    this.tradeName,
    this.stateRegistration,
    this.municipalRegistration,
    this.website,
    this.comments,
    this.status,
    this.parent,
    this.companyTaxType,
    this.fieldActivity
  ];

  render() {
    return (
      <BaseForm refs={this.inputRefs} {...this.props}>
        <BaseFieldSet legend="Empresa">
          <Row>
            <Col xs="12" sm="4" md="3" lg="2" xl="2">
              <BaseInput
                name="id"
                label="Id"
                type="text"
                readOnly
                ref={this.id}
                identifier
              />
            </Col>
            <Col xs="12" sm="8" md="9" lg="10" xl="10">
              <BaseInput
                name="corporateName"
                label="Razão Social"
                type="text"
                required
                maxLength={60}
                ref={this.corporateName}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <BaseInput
                name="tradeName"
                label="Nome Fantasia"
                type="text"
                required
                maxLength={60}
                ref={this.tradeName}
              />
            </Col>
            <Col xs="12" sm="7" md="7" lg="3" xl="3">
              <CnpjInput name="cnpj" required innerRef={this.cnpj} />
            </Col>
            <Col xs="12" sm="5" md="5" lg="3" xl="3">
              <BaseSelect
                label="Status"
                name="statusId"
                type="number"
                required
                ref={this.status}
                endpoint={`${window.serviceUrl}/common/api/v1/Status/Read`}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6" md="6" lg="4" xl="4">
              <BaseInput
                name="municipalRegistration"
                label="Inscrição Municipal"
                type="text"
                maxLength={30}
                ref={this.municipalRegistration}
              />
            </Col>
            <Col xs="12" sm="6" md="6" lg="4" xl="4">
              <BaseInput
                name="stateRegistration"
                label="Inscrição Estadual"
                type="text"
                maxLength={30}
                ref={this.stateRegistration}
              />
            </Col>
            <Col xs="12" sm="12" md="12" lg="4" xl="4">
              <BaseInput
                name="website"
                label="Site"
                type="text"
                maxLength={60}
                ref={this.website}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BaseInput
                name="comments"
                label="Observações"
                type="text"
                as="textarea"
                maxLength={500}
                ref={this.comments}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BaseCheckboxRadioSwitch
                name="isParent"
                label="Matriz"
                type="checkbox"
                ref={this.parent}
              />
            </Col>
          </Row>
        </BaseFieldSet>

        <BaseFieldSet legend="Informações fiscais">
          <Row>
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <BaseInput
                name="fieldActivity"
                label="Ramo de atividade principal"
                type="text"
                maxLength={100}
                required
                ref={this.fieldActivity}
              />
            </Col>
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <BaseSelect
                name="companyTaxType"
                label="Regime tributário"
                type="number"
                options={[
                  { value: 1, label: "Optante do Simples Nacional" },
                  { value: 2, label: "Lucro Presumido" },
                  { value: 3, label: "Lucro Real" }
                ]}
                required
                ref={this.companyTaxType}
              />
            </Col>
          </Row>
        </BaseFieldSet>
      </BaseForm>
    );
  }
}

import React from 'react'
import { BaseGrid, BaseToolbar, ICrud, ITableColumn, CNPJ_MASK } from 'react-epharma-components';
import CrudForm from './Crud';

class CompanySubsidiaryScreen extends React.Component<{[k: string]: any}> {
    
    private columns: ITableColumn[]  = [
        { identifier: true, name: "branchCompanyId", displayName: "Id", sortable: true, width: "5%" },
        { name: "branchCompany.corporateName", displayName: "Razão Social", sortable: true, width: "45%" },
        { name: "branchCompany.cnpj", displayName: "Cnpj", sortable: true, width: "20%", mask: CNPJ_MASK },
        { name: "branchCompany.status.description", displayName: "Status", sortable: true, width: "15%" },
        { name: "branchCompany.createdOn", displayName: "Data de Cadastro", sortable: true, width: "15%", type: "datetime" }
    ];

    private crud: ICrud = {
        form: <CrudForm />,
        create: {
            show: true,
            permissionId: "#CADUNICOBRNCHCREATE"
        },
        // log: {
        //     show: true,
        //     id: 26, 
        //     keys: "id|cnpj"
        // },
        delete: {
            show: true,
            identifiersPropertyName: [
                'branchCompanyId'
            ],
            permissionId: "#CADUNICOBRNCHDELETE"
        }
    };

    render() {
        return (
            <BaseGrid
                name="company-subsidiary"
                baseEndpoint={`${window.serviceUrl}/company/api/v2/companyBranch`}
                toolbar={
                    <BaseToolbar 
                        crud={this.crud}
                    />
                }
                mode={{
                        type: "automatic",
                        automatic: {
                            endpoint: "/Read"
                        },
                        autoload: false,
                        load: this.props.load
                }}
                columns={this.columns}
                filter={this.props.filter}
                dependentProperty={this.props.dependentProperty}
            />
        )
    }
}

export default CompanySubsidiaryScreen
import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  BaseInput,
  BaseSelect,
  BaseForm,
  BaseFieldSet,
  PhoneInput,
  DateInput
} from "react-epharma-components";

export default class CrudForm extends React.Component {
  private id = React.createRef<any>();
  private contactypeId = React.createRef<any>();
  private name = React.createRef<any>();
  private profession = React.createRef<any>();
  private phoneNumber = React.createRef<any>();
  private cellPhoneNumber = React.createRef<any>();
  private birthDate = React.createRef<any>();
  private email = React.createRef<any>();

  private refsArray = [
    this.id,
    this.contactypeId,
    this.name,
    this.profession,
    this.phoneNumber,
    this.cellPhoneNumber,
    this.birthDate,
    this.email
  ];

  render() {
    return (
      <BaseForm refs={this.refsArray} {...this.props}>
        <BaseFieldSet legend="Contato">
          <Row>
            <Col md="6" lg="2" xl="2">
              <BaseInput
                name="id"
                label="Id"
                type="text"
                readOnly
                ref={this.id}
                identifier
              />
            </Col>
            <Col md="6" lg="3" xl="3">
              <BaseSelect
                id="ok"
                label="Tipo"
                name="contactTypeId"
                type="number"
                required
                endpoint={`${window.serviceUrl}/common/api/v1/ContactType/Read`}
                ref={this.contactypeId}
              />
            </Col>
            <Col md="6" lg="4" xl="4">
              <BaseInput
                label="Nome"
                name="name"
                type="text"
                transformCase="upper"
                required
                maxLength={100}
                ref={this.name}
              />
            </Col>
            <Col md="6" lg="3" xl="3">
              <BaseInput
                label="Cargo"
                name="profession"
                type="text"
                transformCase="upper"
                required
                maxLength={200}
                ref={this.profession}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6" lg="3" xl="3">
              <PhoneInput
                label="Telefone Fixo"
                name="phoneNumber"
                phoneType="home_phone"
                required
                innerRef={this.phoneNumber}
              />
            </Col>
            <Col md="6" lg="3" xl="3">
              <PhoneInput
                label="Telefone Celular"
                name="cellPhoneNumber"
                phoneType="cell_phone"
                innerRef={this.cellPhoneNumber}
              />
            </Col>
            <DateInput
              label="Data de Nascimento"
              name="birthDate"
              innerRef={this.birthDate}
              col={{
                md: 6,
                lg: 3,
                xl: 3
              }}
            />
            <Col md="6" lg="3" xl="3">
              <BaseInput
                label="E-mail"
                name="email"
                type="text"
                transformCase="lower"
                maxLength={255}
                ref={this.email}
              />
            </Col>
          </Row>
        </BaseFieldSet>
      </BaseForm>
    );
  }
}

import React from "react";
import { BaseForm, BankInputGroup } from "react-epharma-components";

export default class CrudForm extends React.Component {
    private id = React.createRef<any>();
    private bank = React.createRef<any>();
    private agency = React.createRef<any>();
    private account = React.createRef<any>();

    private refsArray = [
       this.id, this.bank, this.agency, this.account,
    ];

    render() {
        return (
            <BaseForm refs={this.refsArray} {...this.props}>
                <BankInputGroup
                    id={{
                        name: "id",
                        innerRef: this.id
                    }}
                    bank={{
                        name: "bankId",
                        innerRef: this.bank
                    }}
                    agency={{
                        name: "agencyNumber",
                        innerRef: this.agency
                    }}
                    account={{
                        name: "accountNumber",
                        innerRef: this.account
                    }}
                />
            </BaseForm>
        );
    }
}

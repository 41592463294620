import React from "react";
import { BaseForm, AddressInputGroup, BaseInput } from "react-epharma-components";
import { Row, Col } from "react-bootstrap";

export default class CrudForm extends React.Component {
    private id = React.createRef<any>();
    private addressType = React.createRef<any>();
    private zipcode = React.createRef<any>();

    private address = React.createRef<any>();
    private addressNumber = React.createRef<any>();
    private complement = React.createRef<any>();
    private neighborhood = React.createRef<any>();
    private district = React.createRef<any>();
    private addressState = React.createRef<any>();

    private refsArray = [
        this.id, this.addressType, this.zipcode,
        this.address, this.addressNumber, this.complement, 
        this.neighborhood, this.district, this.addressState
    ];

    render() {
        return (
            <BaseForm refs={this.refsArray} {...this.props}>
                <Row>
                    <Col xs="12" sm="4" md="3" lg="2" xl="2">
                        <BaseInput 
                            label="Id" 
                            identifier 
                            name="id" 
                            readOnly
                            ref={this.id} />
                    </Col>
                </Row>
                <AddressInputGroup
                    cep={{
                        name: "zipcode",
                        innerRef: this.zipcode
                    }}
                    street={{
                        name: "address",
                        innerRef: this.address
                    }}
                    number={{
                        name: "addressNumber",
                        innerRef: this.addressNumber
                    }}
                    complement={{
                        name: "complement",
                        innerRef: this.complement
                    }}
                    district={{
                        name: "neighborhood",
                        innerRef: this.neighborhood
                    }}
                    city={{
                        name: "cityId",
                        innerRef: this.district
                    }}
                    state={{
                        name: "city.stateId",
                        innerRef: this.addressState
                    }}
                    type={{
                        name: "addressTypeId",
                        innerRef: this.addressType
                    }}
                />
            </BaseForm>
        );
    }
}
